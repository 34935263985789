import isEqual from 'lodash/isEqual';
import ComponentStore from 'components/data/ComponentStore';
import defaultRatios from 'components/assets/AssetGalleryCropper/data/default-ratios';
import AssetGalleryDialogProps from '../interfaces/AssetGalleryDialogProps';
import AssetGalleryDialogState, { AssetGalleryDialogContentViewType } from '../interfaces/AssetGalleryDialogState';
import AGDialogHelper from './asset-gallery-dialog-helper';
import { AssetGalleryDialogStateKeys, assetGalleryDialogPropToStateMap } from '../interfaces/AssetGalleryDialogPropToStateMap';
import { DEFAULT_APRIMO_OUTPUT_FORMAT, DEFAULT_CROP_MODE, DEFAULT_IMAGE_QUALITY } from '..';

const DEFAULT_SIZE = 0;

/**
 * Different cropped modes
 */
export const MODES = {
    fixed: { aspectRatioLocked: true, sizesFixed: true, startFull: false },
    relative: { aspectRatioLocked: true, sizesFixed: false, startFull: false },
    fixedWidth: { aspectRatioLocked: false, sizesFixed: true, startFull: false },
    free: { aspectRatioLocked: false, sizesFixed: false, startFull: true },
    ratioBased: { aspectRatioLocked: true, sizesFixed: false, startFull: false },
    sizeBased: { aspectRatioLocked: true, sizesFixed: true, startFull: false }
};

/**
 * Helper class that converts the props of the AssetGalleryDialog component to the AssetGalleryDialogState object.
 */
class AssetGalleryDialogPropsToStateHelper {
    /**
     * Converts the props of the AssetGalleryDialog component to the AssetGalleryDialogState object.
     * @param props - The props of the AssetGalleryDialog component.
     * @returns The AssetGalleryDialogState object.
     */
    static convertPropsToState = (props: AssetGalleryDialogProps, selectedContentView: AssetGalleryDialogContentViewType): AssetGalleryDialogState => {
        return {
            isDialogOpen: props.open,
            status: props.status,
            selectedContentView: selectedContentView,
            title: props.title,
            data: {
                contentSpaceData: {
                    contentSpaceCollection: props?.contentSpaceCollection,
                    contentSpaceCollectionQuery: props?.contentSpaceCollectionQuery,
                    contentSpaceToken: props?.contentSpaceToken
                },
                assetData: {
                    imageFormat: props?.imageFormat,
                    imageQuality: props?.imageQuality ?? DEFAULT_IMAGE_QUALITY,
                    outputWidth: props?.outputWidth ?? DEFAULT_SIZE,
                    outputHeight: props?.outputHeight ?? DEFAULT_SIZE,
                    maxOutputWidth: props?.maxOutputWidth ?? DEFAULT_SIZE,
                    maxOutputHeight: props?.maxOutputHeight ?? DEFAULT_SIZE,
                    outpaintMode: props.outpaintMode ?? DEFAULT_CROP_MODE,
                    duration: props?.duration,
                    maximumDuration: props?.maximumDuration,
                    minimumDuration: props?.minimumDuration
                },
                sourceData: props?.sourceData,
                aprimoData: {
                    aprimoOutputFormat: props?.aprimoOutputFormat ?? DEFAULT_APRIMO_OUTPUT_FORMAT,
                    aprimoOptions: props?.aprimoOptions,
                    aprimoTenant: props?.aprimoTenant
                }
            },
            value: { ...props.value },
            config: {
                multiple: props.multiple,
                acceptedTypes: props.fileType,
                maxFileSize: props.maxFileSize,
                initComponentKey: props.initComponentKey,
                cropper: {
                    size: {
                        width: props.outputWidth ?? DEFAULT_SIZE,
                        height: props.outputHeight ?? DEFAULT_SIZE,
                        startFull: MODES[props.cropMode ?? DEFAULT_CROP_MODE].startFull,
                        locked: MODES[props.cropMode ?? DEFAULT_CROP_MODE].aspectRatioLocked,
                        fixed: MODES[props.cropMode ?? DEFAULT_CROP_MODE].sizesFixed
                    },
                    cropMode: AGDialogHelper.getUpdatedCropMode(props.mode, props.cropMode) ?? DEFAULT_CROP_MODE,
                    ratios: props.ratios ? ['0', ...props.ratios] : ['0', ...defaultRatios],
                    isMainCrop: props.isMainCrop ?? false,
                    useImageSize: props.useImageSize ?? false
                }
            },
            conditionProps: this.convertPropsToConditionProps(props),
            ignoreFilterExpired: props.ignoreFilterExpired,
            store: {
                unsplash: {
                    query: ''
                }
            },
            components: {
                sidebar: { selectors: props.selectors ?? [] },
                warningDialog: { isDialogOpen: false }
            }
        };
    };

    /**
     * Get condition props from props parameter and set them into ConditionProps object.
     * @param props Asset gallery dialog props.
     * @returns ConditionProps object.
     */
    static convertPropsToConditionProps = (props: AssetGalleryDialogProps): AssetGalleryDialogState['conditionProps'] => {
        return {
            useCropper: props.useCropper,
            useTrimmer: props.useTrimmer,
            useCompressor: props.useCompressor,
            canMagicEdit: props.canMagicEdit,
            canOutpaint: props.canOutpaint,
            canSkipCropper: props.canSkipCropper,
            canSkipCompressor: props.canSkipCompressor,
            canSkipTrimmer: props.canSkipTrimmer,
            canUseUrl: props.canUseUrl,
            canUpload: props.canUpload,
            canUseUpload: props.canUseUpload,
            canUseAprimo: props.canUseAprimo,
            canUseUnsplash: props.canUseUnsplash,
            canUseContentSpace: props.canUseContentSpace,
            canReuseImage: props.canReuseImage,
            canRemoveBackground: props.canRemoveBackground,
            canRemoveObjects: props.canRemoveObjects,
            userCanCrop: props.userCanCrop,
            canUseAiContent: props.canUseAiContent,
            userCanTrim: props.userCanTrim,
            canUseVoiceOverGenerator: props.canUseVoiceOverGenerator,
            useCustomCompressor: props.useCustomCompressor,
            canUseImageToVideo: props.canUseImageToVideo
        };
    };

    /**
     * Sets the AssetGalleryDialogProps to the ComponentStore.
     * First the AssetGalleryDialogProps will be converted to the AssetGalleryDialogState and then the AssetGalleryDialogState will be set to the ComponentStore.
     * @param props The AssetGalleryDialogProps to be set.
     */
    static setAssetGalleryDialogPropsToComponentStore = (props: AssetGalleryDialogProps) => {
        // Convert the AssetGalleryDialogProps to the AssetGalleryDialogState.
        const assetGalleryDialogState: AssetGalleryDialogState = this.convertPropsToState(props, 'sidebar');

        // Set the AssetGalleryDialogState to the ComponentStore.
        ComponentStore.setData<AssetGalleryDialogState>('AssetGalleryDialog', assetGalleryDialogState);
    };

    /**
     * Updates the ComponentStore with changes from the new props, comparing them to the current props.
     * @param currentProps - The current props before the update.
     * @param newProps - The new props to be applied.
     */
    static updateComponentStore = (currentProps: AssetGalleryDialogProps, newProps: AssetGalleryDialogProps): void => {
        const updates: [AssetGalleryDialogStateKeys, any][] = [];

        // Compare each property and update only if it has changed and is not a function.
        Object.entries(newProps).forEach(([key, value]) => {
            if (typeof value !== 'function' && !isEqual(currentProps[key], value)) {
                const assetGalleryDialogStateKey: AssetGalleryDialogStateKeys | undefined = assetGalleryDialogPropToStateMap[key];

                if (assetGalleryDialogStateKey) {
                    updates.push([assetGalleryDialogStateKey, value]);
                }
            }
        });

        // Update ComponentStore only if there are changes
        if (updates.length > 0) {
            ComponentStore.setMultiModels('AssetGalleryDialog', updates);
        }
    };

    /**
     * Maps the state to props for the AssetGalleryDialog component.
     * @param state - The current state of the application.
     * @returns An object containing the contentSpaceToken property.
     */
    static mapStateToProps = (state) => {
        return {
            contentSpaceToken: state.contentspace ? state.contentspace.token : false
        };
    };
}

export default AssetGalleryDialogPropsToStateHelper;
