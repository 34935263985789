import AssetGalleryDialogState from './AssetGalleryDialogState';
import AssetGalleryDialogProps from './AssetGalleryDialogProps';

/**
 * Get the nested key of an object and the optional properties.
 */
export type NestedKeyOf<ObjectType> = {
    [Key in keyof ObjectType & (string | number)]: Key extends string | number
        ? ObjectType[Key] extends object | undefined
            ? ObjectType[Key] extends any[]
                ? `${Key}`
                : `${Key}.${NestedKeyOf<NonNullable<ObjectType[Key]>>}`
            : `${Key}`
        : never;
}[keyof ObjectType & (string | number)];

/**
 * Asset gallery dialog prop keys.
 */
type AssetGalleryDialogPropsKeys = keyof AssetGalleryDialogProps;
/**
 * Asset gallery dialog state nested keys.
 */
export type AssetGalleryDialogStateKeys = NestedKeyOf<AssetGalleryDialogState>;

/**
 * Mapping type of the asset gallery dialog props keys to the asset gallery dialog component store state keys.
 */
type AssetGalleryDialogPropToStateMap = { [K in AssetGalleryDialogPropsKeys]: AssetGalleryDialogStateKeys | undefined };

/**
 * Mapping of the asset gallery dialog props keys to the asset gallery dialog component store state keys.
 */
export const assetGalleryDialogPropToStateMap: AssetGalleryDialogPropToStateMap = {
    open: 'isDialogOpen',
    status: 'status',
    sourceData: 'data.sourceData',
    title: 'title',
    maxFileSize: 'config.maxFileSize',
    imageFormat: 'data.assetData.imageFormat',
    imageQuality: 'data.assetData.imageQuality',
    duration: 'data.assetData.duration',
    maximumDuration: 'data.assetData.maximumDuration',
    minimumDuration: 'data.assetData.minimumDuration',
    value: 'value',
    format: 'data.assetData.imageFormat',
    multiple: 'config.multiple',
    outputWidth: 'data.assetData.outputWidth',
    outputHeight: 'data.assetData.outputHeight',
    maxOutputWidth: 'data.assetData.maxOutputWidth',
    maxOutputHeight: 'data.assetData.maxOutputHeight',
    outpaintMode: 'data.assetData.outpaintMode',
    userCanCrop: 'conditionProps.userCanCrop',
    useCropper: 'conditionProps.useCropper',
    useTrimmer: 'conditionProps.useTrimmer',
    useCompressor: 'conditionProps.useCompressor',
    canMagicEdit: 'conditionProps.canMagicEdit',
    canOutpaint: 'conditionProps.canOutpaint',
    canSkipCropper: 'conditionProps.canSkipCropper',
    canSkipCompressor: 'conditionProps.canSkipCompressor',
    userCanTrim: 'conditionProps.userCanTrim',
    canSkipTrimmer: 'conditionProps.canSkipTrimmer',
    canUseUrl: 'conditionProps.canUseUrl',
    canUpload: 'conditionProps.canUpload',
    canUseUpload: 'conditionProps.canUseUpload',
    canUseAprimo: 'conditionProps.canUseAprimo',
    canUseUnsplash: 'conditionProps.canUseUnsplash',
    canUseContentSpace: 'conditionProps.canUseContentSpace',
    canReuseImage: 'conditionProps.canReuseImage',
    canRemoveBackground: 'conditionProps.canRemoveBackground',
    canRemoveObjects: 'conditionProps.canRemoveObjects',
    canUseAiContent: 'conditionProps.canUseAiContent',
    canUseVoiceOverGenerator: 'conditionProps.canUseVoiceOverGenerator',
    ignoreFilterExpired: 'ignoreFilterExpired',
    contentSpaceCollection: 'data.contentSpaceData.contentSpaceCollection',
    contentSpaceCollectionQuery: 'data.contentSpaceData.contentSpaceCollectionQuery',
    collectionQuery: undefined,
    contentSpaceToken: 'data.contentSpaceData.contentSpaceToken',
    aprimoOutputFormat: 'data.aprimoData.aprimoOutputFormat',
    aprimoOptions: 'data.aprimoData.aprimoOptions',
    cropMode: 'config.cropper.cropMode',
    mode: undefined,
    selectors: 'components.sidebar.selectors',
    selectorsProps: undefined,
    fetchToken: undefined,
    setFilterExpired: undefined,
    filterExpired: undefined,
    onClose: undefined,
    onMutation: undefined,
    onChangeAcceptedList: undefined,
    ratios: 'config.cropper.ratios',
    aprimoTenant: 'data.aprimoData.aprimoTenant',
    useCustomCompressor: 'conditionProps.useCustomCompressor',
    isMainCrop: 'config.cropper.isMainCrop',
    useImageSize: 'config.cropper.useImageSize',
    fixedHeightPaperScrollPaper: undefined,
    fullWidth: undefined,
    className: undefined,
    canUseImageToVideo: 'conditionProps.canUseImageToVideo',
    initComponentKey: undefined,
    fileType: undefined
};
