import React, { useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import merge from 'lodash/merge';
import Dialog from 'components/ui-components/Dialog';
import ComponentStore from 'components/data/ComponentStore';
import { fetchToken, setFilterExpired } from 'reducers/ContentSpace/actions';
import Setup from 'components/data/Setup';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Translation from 'components/data/Translation';
import AssetGalleryDialogContent from './components/content';
import AGDHelper from './helpers/asset-gallery-dialog-helper';
import AssetGalleryDialogState from './interfaces/AssetGalleryDialogState';
import AssetGalleryDialogProps from './interfaces/AssetGalleryDialogProps';
import AGDPropsToStateHelper from './helpers/asset-gallery-dialog-props-to-state-helper';
import AssetGalleryWarningDialog from './components/warning-dialog';

import './styles/main.scss';

/**
 * Default aprimo selector options
 * At the moment these are the correct options for Philips. If more clients are going to use this, we should put these
 * options in the setup
 */
const APRIMO_DEFAULT_OPTIONS = {
    limitingSearchExpression:
        '(LatestVersionOfMasterFile.Extension = "tif" or LatestVersionOfMasterFile.Extension = "tiff") and not classification.namepath = "/Security/IPR/Restricted" and not classification.namepath = "/PhilipsDraft" and not classification.namepath = "/RecycleBin" and classification.namepath = "/Security/Activation/Active"',
    select: 'single'
};

export const DIALOG_TITLES = {
    audio: Translation.get('assetGalleryDialog.assetAudioTrimmer.title', 'content-space'),
    video: Translation.get('assetGalleryDialog.assetVideoCropper.title', 'content-space'),
    image: Translation.get('assetGalleryDialog.assetEditor.title', 'content-space'),
    default: Translation.get('assetGalleryDialog.title', 'content-space')
};

interface AssetGalleryDialogStateProps {
    selectedContentView: AssetGalleryDialogState['selectedContentView'];
    value: AssetGalleryDialogState['value'];
    config: AssetGalleryDialogState['config'];
}

export const DEFAULT_CROP_MODE = 'fixed';
export const DEFAULT_IMAGE_QUALITY = 0.92;
export const DEFAULT_IMAGE_FORMAT = '';
export const DEFAULT_APRIMO_OUTPUT_FORMAT = 'png';

const DEFAULT_PROPS = {
    aprimoOptions: APRIMO_DEFAULT_OPTIONS,
    aprimoOutputFormat: DEFAULT_APRIMO_OUTPUT_FORMAT,
    canMagicEdit: Setup.hasModule('AI'),
    canOutpaint: Setup.hasModule('AI'),
    canRemoveBackground: Setup.hasModule('AI'),
    canRemoveObjects: Setup.hasModule('AI'),
    canReuseImage: false,
    canSkipCompressor: false,
    canSkipCropper: false,
    canSkipTrimmer: true,
    canUpload: true,
    canUseAprimo: false,
    canUseContentSpace: Setup.hasModule('assetLibrary'),
    canUseUnsplash: Setup.hasModule('assetsUnsplash'),
    canUseUrl: false,
    canUseUpload: true,
    canUseVoiceOverGenerator: false,
    contentSpaceCollection: false,
    contentSpaceCollectionQuery: undefined,
    cropMode: DEFAULT_CROP_MODE,
    duration: undefined,
    fileType: 'image',
    format: '',
    fullWidth: true,
    imageQuality: DEFAULT_IMAGE_QUALITY,
    ignoreFilterExpired: false,
    isMainCrop: false,
    maxOutputHeight: 0,
    maxOutputWidth: 0,
    maximumDuration: undefined,
    minimumDuration: undefined,
    multiple: true,
    open: false,
    outpaintMode: 'free',
    outputHeight: 0,
    outputWidth: 0,
    sourceData: [],
    status: 'gallery',
    title: DIALOG_TITLES['default'],
    useCompressor: false,
    useCropper: false,
    useCustomCompressor: false,
    useTrimmer: false,
    userCanCrop: true,
    userCanTrim: true,
    initComponentKey: null,
    value: {}
};

/**
 * This component is responsible for rendering the dialog content and sidebar.
 */
const AssetGalleryDialog: React.FC<AssetGalleryDialogProps> = (props) => {
    if (!props.open) return null; // If the dialog is not open, return null, this is to prevent the dialog from rendering when it's not open.

    props = useMemo(() => {
        return merge({}, DEFAULT_PROPS, props);
    }, [props]); // Merge the default props with the props, put in an empty object as the first argument to prevent mutation of the default props.

    props.fileType = useMemo(() => AGDHelper.convertSingleFiletypeArrayToString(props.fileType), [props.fileType]);

    const {
        onMutation,
        onChangeAcceptedList,
        onClose,
        title,
        open: isDialogOpen,
        mode,
        cropMode,
        selectorsProps,
        fullWidth,
        fixedHeightPaperScrollPaper,
        className
    } = props;

    const previousPropsRef = useRef<AssetGalleryDialogProps>();

    const { selectedContentView } = useComponentStore<AssetGalleryDialogStateProps>('AssetGalleryDialog', {
        fields: { selectedContentView: 'selectedContentView' }
    });

    const onGoBack = () => {
        // Always go back to the sidebar content view.
        ComponentStore.setModel<AssetGalleryDialogState, 'selectedContentView'>('AssetGalleryDialog', 'selectedContentView', 'sidebar');
    };

    useEffect(() => {
        // Get the updated props including the updated crop mode.
        const newProps = AGDHelper.getUpdatedProps(props, mode, cropMode);

        // On mount, set the initial previousProps
        previousPropsRef.current = newProps;

        // Handle content space props.
        AGDHelper.handleContentSpace(newProps);

        // Set init values to the asset gallery dialog component store.
        AGDPropsToStateHelper.setAssetGalleryDialogPropsToComponentStore(newProps);

        // Set the current content view.
        AGDHelper.handleCurrentContentView(newProps);

        return () => {
            // On unmount, reset the component store.
            ComponentStore.remove('AssetGalleryDialog');
        };
    }, []);

    useEffect(() => {
        const assetGalleryDialog: AssetGalleryDialogState | undefined = ComponentStore.get('AssetGalleryDialog');
        const newProps = AGDHelper.getUpdatedProps(props, mode, cropMode); // Get the updated props including the updated crop mode.

        if (previousPropsRef.current && assetGalleryDialog) {
            // On subsequent renders, compare and update ComponentStore
            AGDPropsToStateHelper.updateComponentStore(previousPropsRef.current, newProps);
        }

        // Check if selectors needs to be updated.
        AGDHelper.handleSidebarSelectors(newProps);

        // Update previousProps for the next comparison
        previousPropsRef.current = newProps;
    }, [props]);

    return (
        <Dialog
            className={className}
            canGoBack={AGDHelper.checkIfBackButtonIsEnabled(selectedContentView)}
            onGoBack={onGoBack}
            title={AGDHelper.getDialogTitle(selectedContentView, title ?? DIALOG_TITLES['default'])}
            fullWidth={fullWidth}
            open={isDialogOpen}
            onClose={() => AGDHelper.handleDialogClose(onClose)}
            fixedHeightPaperScrollPaper={AGDHelper.checkFixedHeightPaperScrollPaper(selectedContentView, fixedHeightPaperScrollPaper)}
            data-cy="assetGalleryDialog-dialog-div">
            <div className="asset-dialog">
                <AssetGalleryDialogContent
                    onClose={onClose}
                    onMutation={onMutation}
                    onChangeAcceptedList={onChangeAcceptedList}
                    onBack={onGoBack}
                    selectorsProps={selectorsProps}
                />
            </div>
            <AssetGalleryWarningDialog
                onClose={() => {
                    AGDHelper.setIsWarningDialogOpen(false);
                }}
                onConfirm={() => AGDHelper.handleWarningDialogConfirm(onClose)}
            />
        </Dialog>
    );
};

export default connect(AGDPropsToStateHelper.mapStateToProps, { fetchToken: fetchToken, setFilterExpired: setFilterExpired })(AssetGalleryDialog);
