import ComponentStore from 'components/data/ComponentStore';

export default class AssetGalleryStore {
    /**
     * Create empty assetGalleryStore
     */
    static init() {
        ComponentStore.setData('CreativeEditor', { assetGalleryStore: { assetGalleryUrls: [], assetGalleryObjects: [] } });
    }

    /**
     * Checks the data of the creative and stores images from the gallery in the assetGalleryStore
     * @param {object} creative the current display or dynamicImage
     */
    static fill(creative) {
        if (!ComponentStore.get('CreativeEditor').assetGalleryStore) this.init();

        /**
         * This function does the actual extraction and saves the results in two arrays one with the urls and one with the full objects.
         * @param {object} creative the current creative
         * @param {object[]} assetGalleryObjects array of asset gallery object (the images with all the asset gallery data)
         * @param {string[]} assetGalleryUrls array of the asset gallery urls
         * @returns
         */
        const extractGalleryUrls = (creative, assetGalleryObjects, assetGalleryUrls) => {
            for (const key in creative) {
                const creativePart = creative[key];
                if (creativePart?.assetGalleryInput === true) {
                    if (assetGalleryUrls.includes(creativePart.url)) return;
                    assetGalleryUrls.push(creativePart.url);
                    assetGalleryObjects.push(creativePart); // Add gallery object to array if assetGalleryInput is true
                } else if (typeof creativePart === 'object') {
                    extractGalleryUrls(creativePart, assetGalleryObjects, assetGalleryUrls); // Recursively check nested objects
                }
            }
        };

        //If creative has frames check for asset gallery input data
        const assetGalleryObjects = [];
        //keep track of urls to prevent duplicates
        const assetGalleryUrls = [];
        extractGalleryUrls(creative.data, assetGalleryObjects, assetGalleryUrls);
        const assetGalleryStore = {
            assetGalleryObjects,
            assetGalleryUrls
        };

        ComponentStore.setData('CreativeEditor', { assetGalleryStore });
    }

    /**
     * If new images are uploaded or chosen they are added to the assetGalleryStore by this function.
     * @param {object} assetToStore asset gallery object
     * @returns
     */
    static storeAsset(assetToStore) {
        let assetGalleryStore = ComponentStore.get('CreativeEditor').assetGalleryStore;
        if (!assetGalleryStore) {
            this.init();
            assetGalleryStore = ComponentStore.get('CreativeEditor').assetGalleryStore;
        }

        //If the asset is already in the store do not store it again
        if (assetGalleryStore.assetGalleryUrls.includes(assetToStore.url)) return;

        //Add asset to asset gallery store
        //If no assets are in the assetGalleryStore create a new one
        const newAssetGalleryStore = {
            assetGalleryUrls: [...assetGalleryStore.assetGalleryUrls, assetToStore.url],
            assetGalleryObjects: [...assetGalleryStore.assetGalleryObjects, assetToStore]
        };

        ComponentStore.setData('CreativeEditor', { assetGalleryStore: newAssetGalleryStore });
    }
}
